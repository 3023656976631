import { socialLoginUrls } from 'src/environments/environment'
import { IAlertService } from 'src/app/libs/ialert/ialerts.service'
import { ApiService } from '../../services/api.service'
import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { UIHelpers } from 'src/app/helpers/ui-helpers'
import { MessagingService } from 'src/app/services/messaging.service'

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup
    loginLoading = false
    webSite: any
    tech: any
    stripeUrlId: any
    isLogin = true

    constructor(
        private api: ApiService,
        private router: Router,
        private fb: FormBuilder,
        public ui: UIHelpers,
        public alert: IAlertService,
        private route: ActivatedRoute,
        private messaging: MessagingService
    ) {
        this.stripeUrlId = this.route.snapshot.queryParamMap.get('stripeUrlId')
        this.webSite = this.route.snapshot.queryParamMap.get('web')
        this.tech = this.route.snapshot.queryParamMap.get('tech')
        this.api.userLoggedInObs.subscribe((value) => {
            this.isLogin = value
        })


    }

    ngOnInit() {
        this.loginForm = this.fb.group({
            youremail: new FormControl(null, [
                Validators.required,
                Validators.maxLength(100),
                Validators.email
            ]),
            yourpassword: new FormControl(null, [Validators.required])
        })
    }

    get g() {
        return this.loginForm.controls
    }

    login(data: any) {
        console.log(data)
        this.loginLoading = true
        if (data.status === 'INVALID') {
            this.alert.error('Please fill-in valid data in all fields & try again.')
            this.loginLoading = false
            return
        }

        data.device_name = 'web'
        const params = {
            email: data.value.youremail,
            password: data.value.yourpassword,
            fcmToken: this.messaging.fcmToken
        }

        this.api.login(params).subscribe((resp: any) => {
            this.loginLoading = false
            if (resp.success === false) {
                //this.alert.error('Invalid Username and password')
                this.alert.error(resp.errors.general)
                return
            }
            console.log(resp)
            this.alert.success('Login successfully!!')
            if (this.route.snapshot.queryParamMap.has('stripeUrlId')) {
                this.router.navigate([`stripe-payment-initiate/${this.stripeUrlId}`])
            } else {
                this.api.doUserRedirects(resp, this.router)
            }
        })
    }
}
